$brand_blue: #017ec3;

.gj-modal {
  color: #3c3d3d;
  border: none;
  border-radius: 4px;
  padding: 4px;

  .gj-picker-bootstrap {
    border: none;

    // Modal Header.
    [role='header'] {
      background: $brand_blue;
      color: #ffffff;

      // Current date.
      [role='date'].selected {
        color: #fff;
      }
    }

    // Main calendar body
    table {
      tr {
        td.disabled div,
        td.other-month div {
          color: #dbdbdb;
        }

        td.today div {
          color: #2c2d2d !important;
          background-color: #c0c1c2b3;
          border-radius: 50%;
          font-weight: 700;
        }

        td.selected.gj-cursor-pointer div {
          color: #fff;
          background-color: $brand_blue;
          border-radius: 50%;
        }

        td.focused div,
        td.gj-cursor-pointer div:hover {
          background: #eee;
          border-radius: 50%;
          color: #212529;
        }

        tr {
          .gj-picker-bootstrap {
            border: none;
            border-radius: 4px;
            padding: 4px;
          }
        }
      }
    }

    // The cancel button.
    .btn:nth-child(1) {
      color: #3c3d3d;
      border: 1.25px solid #c2c2c2af;

      &:hover {
        border: 1px solid #5c5e5e;
      }
    }

    // The OK button.
    .btn:nth-child(2) {
      color: #fff;
      background-color: $brand_blue;
      border-color: $brand_blue;
      &:hover {
        color: #086191;
        background-color: #fff;
        border: 2px solid #017ec3;
      }
    }
  }
}

// Main input element (non modal).
[role='right-icon'] button {
  width: 38px;
  position: relative;
  border: 1px solid #ced4da;
  background-color: #e9ecef;

  &:hover {
    color: #6c757d;
    background-color: #bfc2c5 !important;
  }

  .gj-icon {
    margin-top: 3px;
    left: 7px !important;
  }
}
