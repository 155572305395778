html,
body {
  height: 100%;
}

body {
  padding-bottom: 40px;
}

.form-primary {
  width: 100%;
  max-width: 550px;
  padding: 49px 15px 15px 15px;
  margin: 0 auto;
}
.form-primary .checkbox {
  font-weight: 400;
}
.form-primary .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-primary .form-control:focus {
  z-index: 2;
}
