@import './globals';

form {
  z-index: 999;
  color: #fff;

  .input-group-prepend {
    .input-group-text {
      border-radius: 24px 0 0 24px;
    }
  }

  .input-group-prepend,
  .input-group-append {
    max-height: 44px;
    margin-top: 1px;
  }

  .form-control {
    // Overrides default styles as this input is
    // readonly by default.
    &#datepicker[readonly] {
      background-color: #fff;
      opacity: 1;
    }

    &:disabled,
    &#datepicker:disabled {
      background-color: #e9ecef;
      opacity: 0.5;
    }
  }

  .form-check-input {
    margin-top: 0.48rem;
  }

  .disabled {
    opacity: 0.5;
  }

  .seperator {
    border-top: 1px dashed #ffffff42;
  }
}
