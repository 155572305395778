@import './vendor/bootstrap/boostrap-core';
@import '../styles/vendor/gijgo-date';

/* Navigation 
------------------------------- */
@media (min-width: 992px) {
  .navbar-expand-lg {
    .navbar-nav {
      flex-direction: row;
      margin-left: -201.64px;
    }
    .navbar-collapse {
      display: flex !important;
      justify-content: center;
      flex-basis: auto;
    }
  }
}

.navbar-dark {
  .navbar-toggler {
    color: rgba(255, 255, 255, 0.93);
    border: none !important;
  }
}

/* Background Gradient Fader
------------------------------- */
#canvas-interactive {
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -999;
  background-color: #03b0f6;
  filter: blur(5px);
}

/* Scroll bar
------------------------------- */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(95, 95, 95);
}

::-webkit-scrollbar-thumb {
  background: #fff;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c9c7c7;
}

/* Helper Classes
------------------------------- */
.open-external-link {
  width: 10px;
  height: 10px;
  opacity: 0.25;
}

// Simulate focused element.
.focus-element-psuedo {
  border-color: $input-focus-border-color;
  border-width: 2px;
  box-shadow: $input-focus-box-shadow;
}

// Pulse border animation.
.animate-border-pulse {
  animation: shadow-pulse 1.5s infinite;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
